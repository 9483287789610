import axios from 'axios';

let ip = window.location.origin;

if (window.location.host === 'localhost:3000') {
  ip = 'https://odin.stage.wheelseye.in';
  // ip = 'https://odin.wheelseye.com';
}

const headers: any = {
  locale: 'en',
};

export const getToken = () => window.localStorage.getItem('consignerToken') || '';

export const setToken = (token: string) => window.localStorage.setItem('consignerToken', token);

export const getConsignerCode = () => window.localStorage.getItem('consignerCode') || '';

export const setConsignerCode = (code: string) => window.localStorage.setItem('consignerCode', code);

export const getConsignerName = () => window.localStorage.getItem('consignerName') || '';

export const setConsignerName = (name: string) => window.localStorage.setItem('consignerName', name);

export const getKamContactNumber = () => window.localStorage.getItem('kamContactNumber') || '8447946053';

export const setKamContactNumber = (phone: string) => window.localStorage.setItem('kamContactNumber', phone);

export const logout = () => {
  if (window.localStorage.getItem('consignerToken')) {
    window.localStorage.clear();
  }
  window.location.href = '/consigner-app/login';
};

export const rootPath = ip + '';

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: rootPath,
  headers: headers,
});

// Request interceptor to update consignerToken
api.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('consignerToken');
  !!token && (config.headers.token = token);
  return config;
});

// Add a response interceptor
api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    try {
      if (error.response.status === 401 && error.config.url.match('login')) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject(error);
      }
    } catch (err) {
      return Promise.reject(error);
    }
  },
);

export default api;

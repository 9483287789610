import ActionTypes from './login.types';
import { setToken, setConsignerCode, setConsignerName, setKamContactNumber } from '../../../utils/api.config';

const initialState = {
  response: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
  isLoggedIn: false,
};

const loginReducer = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.LOGIN_START:
      return {
        ...state,
        response: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
        isLoggedIn: false,
      };

    case ActionTypes.LOGIN_SUCCESS:
      setToken(action.payload.token);
      setConsignerCode(action.payload.consignerCode);
      setConsignerName(action.payload.name);
      setKamContactNumber(action.payload?.kamContact?.phone ?? '');
      return {
        ...state,
        response: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
        isLoggedIn: true,
      };

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default loginReducer;

import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const FullscreenLoaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderSpinner = styled.div`
  width: 8rem;
  height: 8rem;
  display: inline-block;
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #0066ff;
  margin-bottom: 1rem;
  animation: 1200ms spin linear infinite;
`;

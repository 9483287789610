import ActionTypes from './account.types';
import { GetBasicDetailsApiResponse } from './account.contracts';

export const getBasicDetailsStart = () => ({
  type: ActionTypes.GET_BASIC_DETAILS_START,
});

export const getBasicDetailsSuccess = (response: GetBasicDetailsApiResponse) => ({
  type: ActionTypes.GET_BASIC_DETAILS_SUCCESS,
  payload: response,
});

export const getBasicDetailsFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_BASIC_DETAILS_FAILURE,
  payload: errorMessage,
});

export const handleLogoutStart = () => ({
  type: ActionTypes.LOGOUT_START,
});

export const handleLogoutSuccess = (response: object) => ({
  type: ActionTypes.LOGOUT_SUCCESS,
  payload: response,
});

export const handleLogoutFailure = (errorMessage: string) => ({
  type: ActionTypes.LOGOUT_FAILURE,
  payload: errorMessage,
});

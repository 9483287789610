import firebase from 'firebase/app';
import { getConsignerCode } from '../utils/api.config';
declare global {
  interface Window {
    gtag?: Function;
  }
}

export const sendToGtag = (action: string, sendTo: string, callback: Function) => {
  window.gtag &&
    window.gtag('event', action, {
      send_to: `G-7VG1J4ZMFF/${sendTo}`,
      event_callback: callback,
    });
};

export const sendToGA = (action: string, label: string, category: string) => {
  window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
};

export const trackPageview = () => {
  window.gtag &&
    window.gtag('event', 'page_view', {
      page_location: window.location.href,
    });
};

export const setUser = () => {
  firebase.analytics().setUserId(getConsignerCode());
};

export const sendEvent = (eventName: string, payload?: any) => {
  let payloadData = payload ? payload : {};
  firebase.analytics().logEvent(eventName, { ...payloadData });
};

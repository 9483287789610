import React from 'react';

import Error from '../../../../assets/icons/error.svg';

import { Container, Wrapper, Text, SubText } from './ErrorFallback.styled';

const ErrorFallback = () => {
  return (
    <Container role="alert">
      <Wrapper>
        <img src={Error} alt="error" />
        <Text className="text">Oops! Something went wrong</Text>
        <SubText className="subText">Please refresh the page or try again later</SubText>
      </Wrapper>
    </Container>
  );
};

export default ErrorFallback;

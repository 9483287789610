import ActionTypes from './account.types';
import { logout } from '../../../../utils/api.config';

const initialState = {
  response: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
};

const accountReducer = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.GET_BASIC_DETAILS_START:
      return {
        ...state,
        response: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_BASIC_DETAILS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_BASIC_DETAILS_FAILURE:
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      logout();
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.LOGOUT_FAILURE:
      logout();
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default accountReducer;

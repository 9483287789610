const types = {
  GET_BASIC_DETAILS_START: 'GET_BASIC_DETAILS_START',
  GET_BASIC_DETAILS_SUCCESS: 'GET_BASIC_DETAILS_SUCCESS',
  GET_BASIC_DETAILS_FAILURE: 'GET_BASIC_DETAILS_FAILURE',
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export default types;

import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../../utils/api.config';
import UrlConfig from '../../../../utils/url.config';

import { getPaymentDueSuccess, getPaymentDueFailure } from './payment-due.actions';

import ActionTypes from './payment-due.types';

interface Url {
  getPaymentDue: Function;
}

const urlConfig: Url = UrlConfig;

function* getPaymentDue() {
  try {
    const response = yield api.get(urlConfig.getPaymentDue());
    yield put(getPaymentDueSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(getPaymentDueFailure(e.message));
  }
}

function* paymentDueSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENT_DUE_START, getPaymentDue);
}

export default paymentDueSaga;

import ActionTypes from './payment-due.types';

const initialState = {
  response: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
};

const paymentDueReducer = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_DUE_START:
      return {
        ...state,
        response: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_PAYMENT_DUE_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_PAYMENT_DUE_FAILURE:
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default paymentDueReducer;

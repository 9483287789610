import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../utils/api.config';
import UrlConfig from '../../../utils/url.config';

import { loginSuccess, loginFailure } from './login.actions';

import ActionTypes from './login.types';

interface Url {
  login: Function;
}

const urlConfig: Url = UrlConfig;

function* postLogin(action: any) {
  try {
    const response = yield api.post(urlConfig.login(), action.payload);
    yield put(loginSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(loginFailure(e.message));
  }
}

function* loginSaga() {
  yield takeLatest(ActionTypes.LOGIN_START, postLogin);
}

export default loginSaga;

import { FunctionComponent, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken, getConsignerCode } from '../utils/api.config';
import { setUser } from '../utils/tracking';

const MakeSubRoutes: FunctionComponent<any> = (route) => {
  const existingToken = getToken();
  const existingConsignercode = getConsignerCode();

  const [authToken] = useState(existingToken);
  const [consignerCode] = useState(existingConsignercode);

  if (!authToken && route.isPrivate) {
    console.log('Redirecting to Login Page');
    window.location.href = '/consigner-app/login';
  }

  //firebase set user
  if (!!consignerCode && route.isPrivate) {
    setUser();
  }

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => {
        return authToken || !route.isPrivate ? (
          <route.component {...props} routes={route.routes} />
        ) : (
          <Redirect to="/consigner-app/login" />
        );
      }}
    />
  );
};

export default MakeSubRoutes;

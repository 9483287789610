import ActionTypes from './order-details.types';
import { GetOrderDetailsApiResponse } from './order-details.contracts';

export const getOrderDetailsStart = (id: string) => ({
  type: ActionTypes.GET_ORDER_DETAILS_START,
  payload: id,
});

export const getOrderDetailsSuccess = (response: GetOrderDetailsApiResponse) => ({
  type: ActionTypes.GET_ORDER_DETAILS_SUCCESS,
  payload: response,
});

export const getOrderDetailsFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_ORDER_DETAILS_FAILURE,
  payload: errorMessage,
});

import React, { FunctionComponent } from 'react';

import { LoaderSpinner, LoaderWrapper, FullscreenLoaderWrapper } from './Loader.styled';

const Loader: FunctionComponent<any> = ({ fullscreen }) => {
  const loader = !!fullscreen ? (
    <FullscreenLoaderWrapper>
      <LoaderSpinner />
    </FullscreenLoaderWrapper>
  ) : (
    <LoaderWrapper>
      <LoaderSpinner />
    </LoaderWrapper>
  );
  return loader;
};

export default Loader;

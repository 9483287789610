import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store/configure-store';
import { routes } from './routes';
import MakeSubRoutes from './make-sub-routes';
import Loader from '../components/UI/atoms/Loader';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

const Routes = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader fullscreen={true} />}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            {routes.map((route: object, index: number) => (
              <MakeSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </QueryParamProvider>
      </Suspense>
    </Provider>
  );
};

export default Routes;

const types = {
  GET_ORDERS_START: 'GET_ORDERS_START',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
  GET_LIVE_REQUESTS_START: 'GET_LIVE_REQUESTS_START',
  GET_LIVE_REQUESTS_SUCCESS: 'GET_LIVE_REQUESTS_SUCCESS',
  GET_LIVE_REQUESTS_FAILURE: 'GET_LIVE_REQUESTS_FAILURE',
};

export default types;

import ActionTypes from './track.types';

export const getConsignmentDataStart = (id: string) => ({
  type: ActionTypes.GET_CONSIGNMENT_DETAILS_START,
  payload: id,
});

export const getConsignmentDataSuccess = (response: any) => ({
  type: ActionTypes.GET_CONSIGNMENT_DETAILS_SUCCESS,
  payload: response,
});

export const getConsignmentDataFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_CONSIGNMENT_DETAILS_FAILURE,
  payload: errorMessage,
});

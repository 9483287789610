import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../../utils/api.config';
import UrlConfig from '../../../../utils/url.config';

import { getOrdersSuccess, getOrdersFailure, getLiveRequestsSuccess, getLiveRequestsFailure } from './orders.actions';

import ActionTypes from './orders.types';

interface Url {
  getOrders: Function;
}

const urlConfig: Url = UrlConfig;

function* getOrders(action: any) {
  try {
    const response = yield api.get(urlConfig.getOrders(action.payload));
    yield put(getOrdersSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(getOrdersFailure(e.message));
  }
}

function* getLiveRequests(action: any) {
  try {
    const response = yield api.get(urlConfig.getOrders(action.payload));
    yield put(getLiveRequestsSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(getLiveRequestsFailure(e.message));
  }
}

function* ordersSaga() {
  yield takeLatest(ActionTypes.GET_ORDERS_START, getOrders);
  yield takeLatest(ActionTypes.GET_LIVE_REQUESTS_START, getLiveRequests);
}

export default ordersSaga;

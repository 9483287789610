import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../utils/api.config';
import UrlConfig from '../../../utils/url.config';

import { getConsignmentDataSuccess, getConsignmentDataFailure } from './track.actions';

import ActionTypes from './track.types';

interface Url {
  getConsignmentData: Function;
}

const urlConfig: Url = UrlConfig;

function* getConsignmentData(action: any) {
  try {
    const response = yield api.get(urlConfig.getConsignmentData(action.payload));
    if (response.data.success) {
      yield put(getConsignmentDataSuccess(response.data.data));
    } else {
      yield put(getConsignmentDataFailure(response.data));
    }
  } catch (e) {
    console.log({ e });
    yield put(getConsignmentDataFailure(e.message));
  }
}

function* trackSaga() {
  yield takeLatest(ActionTypes.GET_CONSIGNMENT_DETAILS_START, getConsignmentData);
}

export default trackSaga;

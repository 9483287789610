import ActionTypes from './orders.types';
import { GetOrdersApiRequest, GetOrdersApiResponse } from './orders.contracts';

export const getOrdersStart = (request: GetOrdersApiRequest) => ({
  type: ActionTypes.GET_ORDERS_START,
  payload: request,
});

export const getOrdersSuccess = (response: GetOrdersApiResponse) => ({
  type: ActionTypes.GET_ORDERS_SUCCESS,
  payload: response,
});

export const getOrdersFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_ORDERS_FAILURE,
  payload: errorMessage,
});

export const getLiveRequestsStart = (request: GetOrdersApiRequest) => ({
  type: ActionTypes.GET_LIVE_REQUESTS_START,
  payload: request,
});

export const getLiveRequestsSuccess = (response: GetOrdersApiResponse) => ({
  type: ActionTypes.GET_LIVE_REQUESTS_SUCCESS,
  payload: response,
});

export const getLiveRequestsFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_LIVE_REQUESTS_FAILURE,
  payload: errorMessage,
});

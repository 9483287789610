import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './report-web-vitals';

import Routes from './routes';
import './global-styles.scss';

import firebase from 'firebase/app';
import 'firebase/analytics';
import { FIREBASE_CONFIG } from './utils/constants/firbaseConfig';
import { init, ErrorBoundary } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ErrorFallback from './components/UI/organisms/ErrorFallback';

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);
firebase.analytics();

if (process.env.REACT_APP_SENTRY) {
  init({ dsn: process.env.REACT_APP_SENTRY, integrations: [new Integrations.BrowserTracing()], tracesSampleRate: 1.0 });
}

ReactDOM.render(
  <ErrorBoundary fallback={ErrorFallback}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import ActionTypes from './payment-due.types';
import { GetPaymentDueApiResponse } from './payment-due.contracts';

export const getPaymentDueStart = () => ({
  type: ActionTypes.GET_PAYMENT_DUE_START,
});

export const getPaymentDueSuccess = (response: GetPaymentDueApiResponse) => ({
  type: ActionTypes.GET_PAYMENT_DUE_SUCCESS,
  payload: response,
});

export const getPaymentDueFailure = (errorMessage: string) => ({
  type: ActionTypes.GET_PAYMENT_DUE_FAILURE,
  payload: errorMessage,
});

import { lazy } from 'react';

const Login = lazy(() => import('../pages/login'));
const Portal = lazy(() => import('../pages/portal'));
const OrderDetails = lazy(() => import('../pages/order-details'));
const ConsignmentTrack = lazy(() => import('../pages/track'));
const BookTrucks = lazy(() => import('../pages/book-trucks'));
const InstallApp = lazy(() => import('../pages/install-app'));

export const routes = [
  {
    path: '/consigner-app/consignment-track',
    component: ConsignmentTrack,
    isPrivate: false,
  },
  {
    path: '/consigner-app/login',
    component: Login,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/consigner-app/portal/order/:orderId',
    component: OrderDetails,
    isPrivate: true,
  },
  {
    path: '/consigner-app/portal',
    component: Portal,
    isPrivate: true,
  },
  {
    path: '/consigner-app/install-app',
    component: InstallApp,
    isPrivate: false,
  },
  {
    path: '/consigner-app/book-trucks',
    component: BookTrucks,
    isPrivate: false,
  },
  {
    path: '/',
    component: BookTrucks,
    isPrivate: false,
  },
  {
    path: '/consigner-app*',
    component: Portal,
    isPrivate: true,
  },
];

import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../utils/api.config';
import UrlConfig from '../../../utils/url.config';

import { getOrderDetailsSuccess, getOrderDetailsFailure } from './order-details.actions';

import ActionTypes from './order-details.types';

interface Url {
  getOrderDetails: Function;
}

const urlConfig: Url = UrlConfig;

function* getOrderDetails(action: any) {
  try {
    const response = yield api.get(urlConfig.getOrderDetails(action.payload));
    yield put(getOrderDetailsSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(getOrderDetailsFailure(e.message));
  }
}

function* orderDetailsSaga() {
  yield takeLatest(ActionTypes.GET_ORDER_DETAILS_START, getOrderDetails);
}

export default orderDetailsSaga;

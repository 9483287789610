import ActionTypes from './track.types';

const initialState = {
  consignmentData: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
};

const track = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.GET_CONSIGNMENT_DETAILS_START:
      return {
        ...state,
        consignmentData: {},
        isLoading: true,
        isFetched: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_CONSIGNMENT_DETAILS_SUCCESS:
      return {
        ...state,
        consignmentData: action.payload,
        isLoading: false,
        isFetched: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_CONSIGNMENT_DETAILS_FAILURE:
      return {
        ...state,
        consignmentData: {},
        isLoading: false,
        isFetched: false,
        hasError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default track;

/*
 * rootSaga
 */

import { spawn, all, call } from 'redux-saga/effects';
import loginSaga from '../pages/login/store/login.saga';
import accountSaga from '../pages/portal/store/account/account.saga';
import ordersSaga from '../pages/portal/store/orders/orders.saga';
import orderDetailssSaga from '../pages/order-details/store/order-details.saga';
import paymentDueSaga from '../pages/portal/store/payment-due/payment-due.saga';
import trackSaga from '../pages/track/store/track.sagas';
import bookTrucksSaga from '../pages/book-trucks/store/book-trucks.saga';

export default function* rootSaga() {
  const sagas = [loginSaga, accountSaga, ordersSaga, orderDetailssSaga, paymentDueSaga, trackSaga, bookTrucksSaga];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}

import ActionTypes from './book-trucks.types';

const initialState = {
  response: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
};

const bookTrucksReducer = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.BOOK_TRUCKS_START:
      return {
        ...state,
        response: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.BOOK_TRUCKS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.BOOK_TRUCKS_FAILURE:
      return {
        ...state,
        response: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default bookTrucksReducer;

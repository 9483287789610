import ActionTypes from './orders.types';

const initialState = {
  ordersData: {},
  liveRequestsData: {},
  isLoading: false,
  hasError: false,
  errorMessage: '',
};

const ordersReducer = (state: object = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERS_START:
      return {
        ...state,
        ordersData: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        ordersData: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_ORDERS_FAILURE:
      return {
        ...state,
        ordersData: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };

    case ActionTypes.GET_LIVE_REQUESTS_START:
      return {
        ...state,
        liveRequestsData: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_LIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        liveRequestsData: action.payload,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      };

    case ActionTypes.GET_LIVE_REQUESTS_FAILURE:
      return {
        ...state,
        liveRequestsData: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default ordersReducer;

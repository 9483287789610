import queryString from 'query-string';

type keyValueObject = {
  [key: string]: any;
};

export const serialize = (queryObject: keyValueObject) => {
  var stringArray = [];
  for (var property in queryObject)
    if (queryObject.hasOwnProperty(property)) {
      stringArray.push(encodeURIComponent(property) + '=' + encodeURIComponent(queryObject[property]));
    }
  return stringArray.join('&');
};

export const isObjectEmpty = (object: object) => {
  return Object.keys(object).length === 0;
};

export const getRupeeString = (amount: number) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(amount);
};

export const toTitleCase = (string: string) => {
  if (!string) {
    return '';
  }
  let tempString = string
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return tempString
    .split('-')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('-');
};

export const truncate = (string: string, length: number) => {
  if (!string) {
    return '';
  }
  if (string && string.length > length) {
    return string.substring(0, length - 2) + '..';
  } else {
    return string;
  }
};

export const getQueryParams = (location: any) => {
  return queryString.parse(location.search);
};

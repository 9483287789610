import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../../utils/api.config';
import UrlConfig from '../../../../utils/url.config';

import {
  getBasicDetailsSuccess,
  getBasicDetailsFailure,
  handleLogoutSuccess,
  handleLogoutFailure,
} from './account.actions';

import ActionTypes from './account.types';

interface Url {
  getBasicDetails: Function;
  logout: Function;
}

const urlConfig: Url = UrlConfig;

function* getBasicDetails() {
  try {
    const response = yield api.get(urlConfig.getBasicDetails());
    yield put(getBasicDetailsSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(getBasicDetailsFailure(e.message));
  }
}

function* handleLogout() {
  try {
    const response = yield api.post(urlConfig.logout());
    yield put(handleLogoutSuccess(response.data));
  } catch (e) {
    console.log({ e });
    yield put(handleLogoutFailure(e.message));
  }
}

export function* accountSaga() {
  yield takeLatest(ActionTypes.GET_BASIC_DETAILS_START, getBasicDetails);
  yield takeLatest(ActionTypes.LOGOUT_START, handleLogout);
}

export default accountSaga;

/*
 * reducers configuration
 */

import { combineReducers } from 'redux';

// import reducers
import loginReducer from '../pages/login/store/login.reducer';
import accountReducer from '../pages/portal/store/account/account.reducer';
import ordersReducer from '../pages/portal/store/orders/orders.reducer';
import orderDetailsReducer from '../pages/order-details/store/order-details.reducer';
import paymentDueReducer from '../pages/portal/store/payment-due/payment-due.reducer';
import track from '../pages/track/store/track.reducer';
import bookTrucksReducer from '../pages/book-trucks/store/book-trucks.reducer';

export default combineReducers({
  loginReducer,
  accountReducer,
  ordersReducer,
  orderDetailsReducer,
  paymentDueReducer,
  track,
  bookTrucksReducer,
});

import ActionTypes from './book-trucks.types';
import { BookTrucksApiRequest } from './book-trucks.contracts';
import { BaseApiResponse } from '../../../store/global.contracts';

export const bookTrucksStart = (request: BookTrucksApiRequest) => ({
  type: ActionTypes.BOOK_TRUCKS_START,
  payload: request,
});

export const bookTrucksSuccess = (response: BaseApiResponse) => ({
  type: ActionTypes.BOOK_TRUCKS_SUCCESS,
  payload: response,
});

export const bookTrucksFailure = (errorMessage: string) => ({
  type: ActionTypes.BOOK_TRUCKS_FAILURE,
  payload: errorMessage,
});

import ActionTypes from './login.types';
import { LoginApiRequest, LoginApiResponse } from './login.contracts';

export const loginStart = (request: LoginApiRequest) => ({
  type: ActionTypes.LOGIN_START,
  payload: request,
});

export const loginSuccess = (response: LoginApiResponse) => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: response,
});

export const loginFailure = (errorMessage: string) => ({
  type: ActionTypes.LOGIN_FAILURE,
  payload: errorMessage,
});

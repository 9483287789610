// All the API URLs needed for the project

import { serialize } from './utils';

const UrlConfig = {
  login: () => {
    return `/odin/v3/consigner/login`;
  },
  logout: () => {
    return `/odin/v3/consigner/logout`;
  },
  getOrders: (query: object) => {
    const queryString = serialize(query);
    return `/odin/v3/consigner/demands?${queryString}`;
  },
  getOrderDetails: (id: string) => {
    return `/odin/v3/consigner/demands/${id}`;
  },
  getBasicDetails: () => {
    return `/odin/v3/consigner/basic-details`;
  },
  getPaymentDue: () => {
    return `odin/v3/consigner/payment-due`;
  },
  getConsignmentData: (id: string) => {
    return `/odin/consigner-app/share-consignment/${id}`;
  },
  bookTrucks: () => {
    return `/odin/v3/consigner/register-consigner`;
  },
};

export default UrlConfig;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
`;

const Wrapper = styled.div`
  text-align: center;
  border: 5px solid #fafafa;
  padding: 50px;
  border-radius: 0 0 5px 5px;
  color: #484545;
`;

const Text = styled.div`
  font-size: 2.5rem;
  margin: 30px 0 10px 0;
`;

const SubText = styled.div`
  font-size: 1.5rem;
  margin: 0;
  color: #aaaaaa;
`;

export { Container, Wrapper, Text, SubText };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBpJ1ZA6pwYIkK5OsY7JENqyBQjvZJdd5M',
  authDomain: 'wheelseye-178710.firebaseapp.com',
  databaseURL: 'https://wheelseye-178710.firebaseio.com',
  projectId: 'wheelseye-178710',
  storageBucket: 'wheelseye-178710.appspot.com',
  messagingSenderId: '20420679268',
  appId: '1:20420679268:web:90a13f4133ec34cef33171',
  measurementId: 'G-YGNP81E73G',
};

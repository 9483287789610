import { takeLatest, put } from 'redux-saga/effects';
import api from '../../../utils/api.config';
import UrlConfig from '../../../utils/url.config';

import { bookTrucksSuccess, bookTrucksFailure } from './book-trucks.actions';

import ActionTypes from './book-trucks.types';

interface Url {
  bookTrucks: Function;
}

const urlConfig: Url = UrlConfig;

function* postBookTrucks(action: any) {
  try {
    const response = yield api.post(urlConfig.bookTrucks(), action.payload);
    yield put(bookTrucksSuccess(response.data.data));
  } catch (e) {
    console.log({ e });
    yield put(bookTrucksFailure(e.message));
  }
}

function* bookTrucksSaga() {
  yield takeLatest(ActionTypes.BOOK_TRUCKS_START, postBookTrucks);
}

export default bookTrucksSaga;
